<template>
  <div class="container">
    <div class="header">
      <Header></Header>
    </div>
    <div class="nav">
      <Nav :navData="navList"></Nav>
    </div>
    <div class="body">
      <!-- 全图滚动 -->
      <div
        v-if="close == 1"
        class="notice"
        :style="{ top: top + 'px', left: left + 'px' }"
        @mouseenter="enter2()"
        @mouseleave="leave2()"
      >
        <div class="notice-top">
          <img src="../assets/images/icon-notice.png" class="notify" />
          <img
            src="../assets/images/icon-close.png"
            class="close"
            @click="closeSwitch()"
          />
          <div class="close-but" @click="closeSwitch()">关闭</div>
        </div>
        <div class="content" @click="jumpNotice()">{{ noticeAll.title }}</div>
      </div>
      <div class="wCenter">
        <!-- 协会新闻 公告 -->
        <div class="news-notice">
          <div class="nav-list">
            <div class="nav-top">
              <div class="nav-title">分支机构</div>
              <div
                v-for="(mech_item, mech_index) in mech"
                :key="mech_index"
                class="nav-info"
                @click="jump(mech_item.id, mech_item.parent_id)"
              >
                {{ mech_item.name }}
              </div>
            </div>
            <div class="nav-bot">
              <div class="nav-title">服务中心</div>
              <div
                v-for="(service_item, service_index) in service"
                :key="service_index"
                class="nav-info"
                @click="jump(service_item.id, service_item.parent_id)"
              >
                {{ service_item.name }}
              </div>
            </div>
          </div>
          <div class="news">
            <div class="news-img">
              <img src="../assets/images/news.png" alt="" />
              <div class="news-more" @click="jump(9, 8)">更多>></div>
              <div class="news-not">协会新闻</div>
            </div>
            <div class="news-midd">
              <div class="news-div">
                <!-- :style="{ left: left_banner + 'px' }" -->
                <ul
                  class="news-ul"
                  :style="{
                    transform: `translate(${left_banner})`,
                    transition: wait + 's',
                  }"
                >
                  <li
                    v-for="(banner_item, banner_index) in banner"
                    :key="banner_index"
                  >
                    <a :href="banner_item.url" target="__blank">
                      <img :src="banner_item.image" alt=""
                    /></a>
                  </li>

                  <li>
                    <a :href="first.url" target="__blank"
                      ><img :src="first.image" alt=""
                    /></a>
                  </li>
                </ul>
                <!-- 轮播标题 -->
                <div class="banner-title">
                  <div class="banner-left" style="text-overflow: ellipsis">
                    {{ banner_title }}
                  </div>
                  <div class="banner-right">
                    <ul>
                      <li v-for="(li_item, li_index) in banner" :key="li_index">
                        <div
                          :class="[
                            icon_index - 1 == li_index ? 'icon-active' : 'icon',
                          ]"
                          @click="changeIcon(li_index)"
                        ></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="notices">
            <div class="news-img">
              <img src="../assets/images/title.png" alt="" />
              <div class="news-more" @click="jump(10, 8)">更多>></div>
              <div class="news-not">协会公告</div>
            </div>
            <div class="title-list-re">
              <div class="title-list" :style="{ top: newsTopl + 'px' }">
                <div
                  v-for="(not_item, not_index) in notice"
                  :key="not_index"
                  class="title-item"
                  @mouseenter="enter()"
                  @mouseleave="leave()"
                  @click="jumpInfo(not_item.id, 10, 8)"
                >
                  <div v-if="not_index < 3" class="number-front">
                    {{ not_index + 1 }}
                  </div>
                  <div v-else class="number">{{ not_index + 1 }}、</div>
                  <div class="title">{{ not_item.post_title }}</div>
                  <div class="date">[{{ not_item.published_timed }}]</div>
                </div>
              </div>
              <div class="title-list" :style="{ top: newsTopr + 'px' }">
                <div
                  v-for="(not_item, not_index) in notice"
                  :key="not_index"
                  class="title-item"
                  @mouseenter="enter()"
                  @mouseleave="leave()"
                  @click="jumpInfo(not_item.id, 10, 8)"
                >
                  <div v-if="not_index < 3" class="number-front">
                    {{ not_index + 1 }}
                  </div>
                  <div v-else class="number">{{ not_index + 1 }}、</div>
                  <div class="title">{{ not_item.post_title }}</div>
                  <div class="date">[{{ not_item.published_timed }}]</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 功能直达 -->
        <div class="fun">
          <div class="fun-img">
            <img src="../assets/images/fun.png" alt="" />
            <div class="news-not">功能直达</div>
          </div>
          <div class="fun-item">
            <!-- 证书查询 -->
            <div class="fun-left">
              <router-link to="/certficate">
                <div class="select">
                  <img src="../assets/images/icon/find.png" alt="" />
                  <div class="select-title">证书查询</div>
                </div>
                <div class="book">
                  <img
                    src="../assets/images/icon/sanjiao@2x.png"
                    alt=""
                    style="width: 8px; height: 12px"
                  />
                  协会培训证书
                </div>
              </router-link>
              <div
                v-for="(cert_item, cert_index) in cert"
                :key="cert_index"
                class="book"
                @click="openHref(cert_item.url)"
              >
                <img
                  src="../assets/images/icon/sanjiao@2x.png"
                  alt=""
                  style="width: 8px; height: 12px"
                />
                {{ cert_item.title }}
              </div>
            </div>
            <!-- 其他查询 -->
            <div class="fun-right">
              <div
                v-for="(itemf, indexf) in fun"
                :key="indexf"
                class="right-item"
              >
                <a :href="itemf.url" target="__blank">
                  <img :src="itemf.image" alt="" />
                  <div class="fun-title">{{ itemf.title }}</div></a
                >
              </div>
            </div>
            <!-- 信用川电 -->
            <div class="fun-right-right">
              <div class="select">
                <img src="../assets/images/icon/xin.png" alt="" />
                <div class="select-title">信用川电</div>
              </div>
              <div
                v-for="(xin_item, xin_index) in xin.des"
                :key="xin_index"
                class="book"
              >
                {{ xin_item }}
              </div>
              <div class="go-now">
                <a :href="xin.href" target="__blank" style="color: #005a69"
                  >立即前往>></a
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 新闻板块 -->
        <div class="news2">
          <div class="news-left">
            <div class="news-title">
              <div class="title">党团建设</div>
              <div class="news-more" @click="jump(1, 1)">更多>></div>
            </div>
            <div class="title-list">
              <div
                v-for="(build_item, build_index) in build"
                :key="build_index"
                class="title-item"
                @click="jumpInfo(build_item.id, 1, 1)"
              >
                <div class="title">{{ build_item.post_title }}</div>
                <div class="date">[{{ build_item.published_timed }}]</div>
              </div>
            </div>
          </div>
          <div class="news-right">
            <div class="news-title">
              <div
                :class="[active_id == 1 ? 'title act' : 'title']"
                @click="getContent(1, 2, 2)"
              >
                政策法规
              </div>
              <div
                :class="[active_id == 2 ? 'title act' : 'title']"
                @click="getContent(2, 11, 8)"
              >
                行业资讯
              </div>
              <div
                :class="[active_id == 3 ? 'title act' : 'title']"
                @click="getContent(3, 17, 12)"
              >
                会员动态
              </div>
              <div
                :class="[active_id == 4 ? 'title act' : 'title']"
                @click="getContent(4, 20, 19)"
              >
                分支机构
              </div>
              <div class="news-more" @click="jumpmore()">更多>></div>
            </div>
            <div class="list">
              <div
                v-for="(cont_item, cont_index) in content"
                :key="cont_index"
                class="list-all"
                @click="jumpInfo1(cont_item.id)"
              >
                <div class="list-flex">
                  <div class="title">{{ cont_item.post_title }}</div>
                  <div class="date">[{{ cont_item.published_timed }}]</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 背景图 -->
        <div class="bghome">
          <a :href="middImage.url"><img :src="middImage.image" alt="" /></a>
        </div>
        <!-- 会员中心 -->
        <div class="member">
          <div class="member-img">
            <img src="../assets/images/member.png" alt="" />
            <div class="news-more" @click="jump(16, 12)">更多>></div>
            <div class="news-not">会员风采</div>
          </div>
          <div class="containt">
            <div>
              <ul :style="{ left: calleft + 'px' }">
                <li
                  v-for="(member_it, member_index) in member"
                  :key="member_index"
                  @click="jumpInfo(member_it.id, 16, 7)"
                >
                  <img :src="member_it.thumbnail" alt="" />
                  <div class="company">{{ member_it.post_title }}</div>
                </li>
              </ul>
              <ul :style="{ left: calleft1 + 'px' }">
                <li
                  v-for="(member_it, member_index) in member"
                  :key="member_index"
                  @click="jumpInfo(member_it.id, 16, 7)"
                >
                  <img :src="member_it.thumbnail" alt="" />
                  <div class="company">{{ member_it.post_title }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import {
  getHome,
  getMechanism,
  getNav,
  getcertificate,
  otherlink,
} from "@/http/api/home/index";
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Nav from "../components/nav.vue";
export default {
  data() {
    return {
      isActive: 0,
      top: 0,
      left: 0,
      typex: 1,
      typey: 1,
      calleft: 0,
      calleft1: 1800,
      calleft2: 0,
      mech: [],
      service: [],
      banner: [], //轮播图
      banner_title: "",
      active_index: 1, //轮播图选中的id
      icon_index: 1,
      left_banner: "0px",
      first: [],
      wait: 2, //秒级移动
      newsTopl: 0, //控制协会公告
      newsTopr: 400,
      newTop: 0,
      notice: [], //协会公告
      navList: [],
      build: [], //建设
      rollSwitch: [],
      rollSwitch2: [],
      news_id: 2,
      nav_id: 2, // 政策法规
      active_id: 1,
      content: [],
      member: [], //会员风采
      cert: [], //证书
      fun: [], //功能直达
      middImage: "", // 中部图片
      xin: [], //信用川电
      close: 0, //1显示全图滚动 2不显示
      noticeAll: [],
    };
  },
  components: {
    Header,
    Nav,
    Footer,
  },
  created() {
    //获取导航
    this.getNav();
    this.getHome();
    this.getMechanism(); //获取分支机构
    this.getService(); //获取服务中心
    this.getNotice(); //获取协会公告
    this.getBuild();
    this.getCert(); //获取证书
    this.getContent(this.active_id, this.news_id, this.nav_id);
    this.getMember(); //会员风采
    this.getFun(); //功能直达
    this.rollSwitch2 = setInterval(this.get, 10 );
    setInterval(this.starmove, 20);
    setInterval(this.changeBanner, 5000);
    this.rollSwitch = setInterval(this.newsList, 30);
  },
  methods: {
    get() {
      var height = 250;
      var width = document.documentElement.clientWidth;
      if (this.typex == 1) {
        this.top = this.top + 0.5;
      } else {
        this.top = this.top - 0.5;
      }
      if (this.typey == 1) {
        this.left = this.left + 0.4;
      } else {
        this.left = this.left - 0.4;
      }
      if (this.top == 0) {
        this.typex = 1;
      }
      if (this.top > height) {
        this.typex = 2;
      }
      if (this.left == 0) {
        this.typey = 1;
      }
      if (this.left > width - 170) {
        this.typey = 2;
      }
    },
    starmove() {
      this.calleft = this.calleft2;
      this.calleft1 = this.calleft2 + 1800;
      this.calleft2--;
      if (this.calleft2 + 1800 == 0) {
        this.calleft2 = 0;
      }
    },
    //获取导航
    getHome() {
      getHome().then((res) => {
        this.banner = res.data.data.banner;
        this.first = this.banner[0];
        this.banner_title = this.banner[0]["title"];
        this.middImage = res.data.data.image;
        this.noticeAll = res.data.data.notice;
        this.close = this.noticeAll.status;
      });
    },
    getMechanism() {
      getMechanism({ news_id: 20 }).then((res) => {
        this.mech = res.data.data.nav;
      });
    },
    getService() {
      getMechanism({ news_id: 25 }).then((res) => {
        this.service = res.data.data.nav;
      });
    },
    changeBanner() {
      this.wait = 2;
      var num = -(this.active_index * 450);
      this.left_banner = num + "px";
      this.active_index += 1;
      if (this.active_index == this.banner.length + 2) {
        this.wait = 0;
        this.left_banner = "0px";
        this.active_index = 1;
      }
      //修改名字
      if (
        this.active_index == this.banner.length + 1 ||
        this.active_index == 1
      ) {
        this.banner_title = this.banner[0]["title"];
      } else {
        this.banner_title = this.banner[this.active_index - 1]["title"];
      }
      if (this.active_index == this.banner.length + 1) {
        this.icon_index = 1;
      } else {
        this.icon_index = this.active_index;
      }
    },
    newsList() {
      this.newsTopl = this.newTop;
      this.newsTopr = this.newTop + 400;
      this.newTop--;
      if (this.newTop + 400 == 0) {
        this.newTop = 0;
      }
    },
    getNotice() {
      getMechanism({ news_id: 10 }).then((res) => {
        this.notice = res.data.data.data.data;
      });
    },
    getContent(id, news_id, nav_id) {
      this.active_id = id;
      this.news_id = news_id;
      this.nav_id = nav_id;
      getMechanism({ news_id: news_id }).then((res) => {
        this.content = res.data.data.data.data;
      });
    },
    getMember() {
      getMechanism({ news_id: 16 }).then((res) => {
        this.member = res.data.data.data.data;
      });
    },
    jump(news_id, nav_id) {
      var url = "/news?news_id=" + news_id + "&nav_id=" + nav_id;
      this.$router.push({ path: url });
    },
    jumpInfo(id, news_id, nav_id) {
      var url = "/info?id=" + id + "&news_id=" + news_id + "&nav_id=" + nav_id;
      this.$router.push({ path: url });
    },
    jumpInfo1(id) {
      var url =
        "/info?id=" +
        id +
        "&news_id=" +
        this.news_id +
        "&nav_id=" +
        this.nav_id;
      this.$router.push({ path: url });
    },
    jumpmore() {
      var url = "/news?news_id=" + this.news_id + "&nav_id=" + this.nav_id;
      this.$router.push({ path: url });
    },
    getNav() {
      getNav().then((res) => {
        this.navList = res.data.data;
      });
    },
    getBuild() {
      getMechanism({ news_id: 1 }).then((res) => {
        this.build = res.data.data.data.data;
      });
    },
    enter() {
      clearInterval(this.rollSwitch);
    },
    leave() {
      this.rollSwitch = setInterval(this.newsList, 30);
    },
    enter2() {
      clearInterval(this.rollSwitch2);
    },
    leave2() {
      this.rollSwitch2 = setInterval(this.get, 7);
    },
    getCert() {
      getcertificate().then((res) => {
        this.cert = res.data.data;
      });
    },
    jumpCert(index) {
      var url = "/certficate?type=" + index;
      this.$router.push({ path: url });
    },
    getFun() {
      otherlink().then((res) => {
        var data = res.data.data;
        var fun = [];
        for (var i = 0; i < data.length; i++) {
          if (data[i]["title"] == "信用川电") {
            this.xin.des = data[i]["description"].split(" ");
            this.xin.href = data[i]["url"];
          } else {
            fun.push(data[i]);
          }
        }
        this.fun = fun;
      });
    },
    closeSwitch() {
      this.close = 2;
    },
    jumpNotice() {
      var url = "/about?id=999&key=" + this.noticeAll.key;
      this.$router.push({ path: url });
    },
    openHref(url) {
      window.open(url);
    },
    //点击修改轮播图
    changeIcon(index) {
      this.active_index = index;
      var num = -(this.active_index * 450);
      this.left_banner = num + "px";
      //修改名字
      this.banner_title = this.banner[index]["title"];
      this.icon_index = index + 1;
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  background: #f7f7f7;
  padding-bottom: 30px;
  .notice {
    width: 192px;
    height: 84px;
    background-color: #ffffff;
    position: fixed;
    z-index: 999999;
    // top: 370px;
    // left: 100px;
    .notice-top {
      padding-top: 8px;
      .notify {
        width: 62px;
        height: 22px;
        display: inline-block;
        vertical-align: middle;
      }
      .close {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 76px;
        cursor: pointer;
      }
      .close-but {
        width: 26px;
        font-weight: 400;
        color: #bbbbbb;
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        cursor: pointer;
      }
    }
    .content {
      background-color: white;
      padding: 8px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      font-weight: 700;
      text-align: left;
      color: #02454b;
      line-height: 19px;
      letter-spacing: 1px;
      cursor: pointer;
    }
  }
  .news-notice {
    width: 100%;
    height: 422px;
    display: flex;
    padding-top: 30px;
    .nav-list {
      width: 298px;
      height: 392px;
      background: #ffffff;
      overflow: hidden;
      .nav-top {
        padding: 0 15px;
        .nav-title {
          font-size: 16px;
          font-weight: 700;
          color: #333333;
          height: 45px;
          line-height: 45px;
        }
        .nav-info {
          width: 100%;
          height: 32px;
          background: linear-gradient(90deg, #076a81 0%, #0b918b 100%);
          cursor: pointer;
          margin-bottom: 4px;
          color: #ffffff;
          font-size: 14px;
          line-height: 32px;
          padding-left: 10px;
        }
        .nav-info:hover {
          background: linear-gradient(90deg, #008a9b, #25dad4);
        }
      }
      .nav-bot {
        padding: 0 15px;
        .nav-title {
          font-size: 16px;
          font-weight: 700;
          color: #333333;
          height: 45px;
          line-height: 45px;
        } 
        .nav-info {
          width: 100%;
          height: 32px;
          background: linear-gradient(90deg, #076a81 0%, #0b918b 100%);
          margin-bottom: 4px;
          color: #ffffff;
          font-size: 14px;
          line-height: 32px;
          padding-left: 10px;
          cursor: pointer;
        }
        .nav-info:hover {
          background: linear-gradient(135deg, #25dad4, #008a9b);
        }
      }
    } 
    .news {
      width: 490px;
      height: 392px;
      background: #ffffff;
      padding: 20px;
      .news-img {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .news-more {
          position: absolute;
          right: 0px;
          top: 10px;
          font-size: 12px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #005a69;
          cursor: pointer;
        }
        .news-more:hover {
          color: #059e96;
        }
        .news-not {
          font-size: 16px;
          font-weight: 700;
          color: #ffffff;
          position: absolute;
          top: 5px;
          left: 18px;
        }
      }
      .news-midd {
        width: 100%;
        padding-top: 20px;
        display: flex;
        .news-div {
          position: relative;
          width: 450px;
          height: 300px;
          overflow: hidden;
          margin: 0 auto;
          .news-ul {
            width: 9000px;
            height: 100%;
            position: absolute;
            li {
              width: 450px;
              height: 300px;
              float: left;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

          .banner-title {
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: 30px;
            line-height: 30px;
            background-color: rgba(0, 0, 0, 0.3);
            // background: #000000;
            display: flex;
            .banner-left {
              width: 300px;
              margin-left: 8px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .banner-right {
              width: 142px;
              ul {
                float: right;
                li { 
                  display: inline-block;
                  margin-right: 6px;
                  .icon {
                    width: 6px;
                    height: 6px;
                    background: #ffffff;
                    border-radius: 50%;
                    opacity: 0.51;
                    cursor: pointer;
                  }
                  .icon-active {
                    width: 6px;
                    height: 6px;
                    background: #ffffff;
                    border-radius: 50%;
                    opacity: 1;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
    .notices {
      width: 390px;
      height: 392px;
      margin-left: 20px;
      background: #ffffff;
      padding: 20px;
      .news-img {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .news-more {
          position: absolute;
          right: 0px;
          top: 10px;
          font-size: 12px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #005a69;
          cursor: pointer;
        }
        .news-more:hover {
          color: #059e96;
        }
        .news-not {
          font-size: 16px;
          font-weight: 700;
          color: #ffffff;
          position: absolute;
          top: 5px;
          left: 18px;
        }
      }
      .title-list-re {
        position: relative;
        height: 330px;
        overflow: hidden;
        .title-list {
          position: absolute;
          height: auto;
          .title-item {
            cursor: pointer;
            margin-left: 15px;
            margin-top: 21px;
            width: 100%;
            height: 19px;
            display: flex;
            .number {
              width: 16px;
              height: 16px;
              line-height: 19px;
            }
            .number-front {
              width: 16px;
              height: 16px;
              background: #059e96;
              font-size: 10px;
              font-weight: 700;
              text-align: center;
              color: #ffffff;
              line-height: 16px;
            }
            .title {
              width: 232px;
              margin-left: 6px;
              font-size: 14px;
              font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
              color: #333333;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .date {
              margin-left: 17px;
              font-size: 14px;
              font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
              color: #999999;
              line-height: 19px;
            }
          }
          .title-item :hover {
            color: #059e96;
          }
        }
      }
    }
  }
  .fun {
    width: 100%;
    margin-top: 30px;
    .fun-img {
      position: relative;
      .news-not {
        font-size: 16px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
        font-weight: 700;
        color: #ffffff;
        position: absolute;
        top: 5px;
        left: 18px;
      }
    }
    .fun-item {
      margin-top: 20px;
      background: #ffffff;
      display: flex;
      .fun-left {
        width: 200px;
        height: 138px;
        overflow: hidden;
        .select {
          padding: 10px 0px 0px 10px;
          height: 54px;
          img {
            width: 44px;
            height: 44px;
            display: inline-block;
            vertical-align: middle;
          }
          .select-title {
            width: calc(100% - 44px);
            height: 44px;
            display: inline-block;
            line-height: 44px;
            font-size: 14px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
            font-weight: 700;
            color: #333333;
          }
        }
        .book {
          margin-left: 54px;
          font-size: 12px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 14px;
          padding-bottom: 10px;
          margin-top: 3px;
          cursor: pointer;
        }
        .book:hover {
          color: #059e96;
        }
      }
      .fun-right {
        width: 800px;
        height: 138px;
        overflow: hidden;
        .right-item {
          width: 200px;
          height: 69px;
          display: inline-block;
          img {
            width: 44px;
            height: 44px;
            display: inline-block;
            vertical-align: middle;
          }
          .fun-title {
            line-height: 69px;
            font-size: 14px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
            font-weight: 700;
            color: #333333;
            display: inline-block;
          }
        }
      }
      .fun-right-right {
        width: 200px;
        height: 138px;
        .select {
          padding: 10px 0px 0px 10px;
          height: 54px;
          img {
            width: 44px;
            height: 44px;
            display: inline-block;
            vertical-align: middle;
          }
          .select-title {
            width: calc(100% - 44px);
            height: 44px;
            display: inline-block;
            line-height: 44px;
            font-size: 14px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
            font-weight: 700;
            color: #333333;
          }
        }
        .book {
          margin-left: 54px;
          font-size: 12px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 14px;
          margin-bottom: 5px;
          margin-top: 1px;
        }
        .go-now {
          margin-left: 54px;
          font-size: 12px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          font-weight: 400;
          text-align: left;
          line-height: 14px;
          margin-top: 10px;
        }
      }
    }
  }
  .news2 {
    width: 100%;
    height: 277px;
    display: flex;
    padding-top: 30px;
    .news-left {
      width: 390px;
      height: 247px;
      background: #ffffff;
      overflow: hidden;
      .news-title {
        width: 390px;
        height: 52px;
        padding: 0px 10px;
        border-bottom: 1px solid #dddddd;
        position: relative;
        .title {
          width: 84px;
          height: 52px;
          font-size: 16px;
          font-weight: 700;
          color: #059e96;
          line-height: 52px;
          padding: 0px 10px;
          border-bottom: 2px solid #059e96;
        }
        .news-more {
          position: absolute;
          right: 20px;
          top: 20px;
          font-size: 12px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #005a69;
          cursor: pointer;
        }
        .news-more:hover {
          color: #059e96;
        }
      }
      .title-list {
        .title-item {
          cursor: pointer;
          margin-left: 15px;
          margin-top: 16px;
          width: 100%;
          height: 19px;
          display: flex;
          .number {
            width: 16px;
            height: 16px;
            line-height: 19px;
          }
          .title {
            width: 301px;
            margin-left: 6px;
            font-size: 14px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            color: #333333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .title:hover {
            color: #059e96;
          }
          .date {
            font-size: 14px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            color: #999999;
            line-height: 19px;
          }
        }
        .news-more:hover {
          color: #059e96;
        }
      }
    }
    .news-right {
      width: 790px;
      height: 247px;
      margin-left: 20px;
      background-color: #ffffff;
      .news-title {
        height: 52px;
        padding: 0px 10px;
        border-bottom: 1px solid #dddddd;
        position: relative;
        .title {
          width: 84px;
          height: 52px;
          font-size: 16px;
          font-weight: 700;
          color: #333333;
          line-height: 52px;
          padding: 0px 10px;
          display: inline-block;
          cursor: pointer;
        }
        .act {
          color: #059e96;
          border-bottom: 2px solid #059e96;
        }
        .news-more {
          position: absolute;
          right: 20px;
          top: 20px;
          font-size: 12px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #005a69;
          cursor: pointer;
        }
        .news-more:hover {
          color: #059e96;
        }
      }
      .list {
        padding: 0px 10px;
        height: 193px;
        .list-all {
          width: 50%;
          display: inline-block;
          padding-top: 16px;
          .list-flex {
            display: flex;
            .title {
              width: 280px;
              margin-left: 6px;
              font-size: 14px;
              color: #333333;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              cursor: pointer;
            }
            .title:hover {
              color: #059e96;
            }
            .date {
              margin-left: 21px;
              font-size: 14px;
              color: #999999;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
  .bghome {
    width: 100%;
    height: 90px;
    margin-top: 30px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .member {
    width: 100%;
    height: 302px;
    background: #ffffff;
    margin-top: 30px;
    .member-img {
      padding: 20px;
      position: relative;
      .news-more {
        position: absolute;
        right: 19px;
        bottom: 36px;
        font-size: 12px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #005a69;
        cursor: pointer;
      }
      .news-more:hover {
        color: #059e96;
      }
      .news-not {
        font-size: 16px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
        font-weight: 700;
        color: #ffffff;
        position: absolute;
        top: 25px;
        left: 38px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .containt {
      position: relative;
      width: 1160px;
      height: 191px;
      overflow: hidden;
      margin: 0 auto;
      margin-left: 20px;
      margin-right: 20px;
      ul {
        margin: 0 auto;
        width: 1800px;
        position: absolute;
        left: 0px;
        cursor: pointer;
        height: 100%;
        li {
          width: 160px;
          margin-right: 20px;
          float: left;
          img {
            height: 160px;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .company {
        font-size: 13px;
        margin-top: 10px;
        color: #333333;
        text-align: center;
      }
      .company:hover {
        color: #059e96;
      }
    }
  }
}
</style>
